import React, {useCallback, useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, FormCheck, Row, Table,} from "@govtechsg/sgds-react";
import Select from "react-select";
import CustomModal from "@/components/CustomModal";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getCodeList, getStudentDetail} from "@/apis/sims/api";
import {ApplicationStatus, VerificationStatus} from "@/apis/app-records/maintenance/status";
import {GetApplications} from "@/apis/reports/api";
import jsPDF from "jspdf";
import {utils, writeFileXLSX} from "xlsx";
import autoTable from "jspdf-autotable";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {postStudentList} from "@/apis/app-records/verification/api";
import {sortDescendingByKey, sortExerciseList} from "@/components/utils/utils";
import {getRemarkNYP} from "@/apis/preparation/setup-remarks/api";
import {getApplicationListByAcadYrExercise} from "@/apis/app-records/maintenance/api";

const options = [
    {value: "", label: ""},
    {value: "W", label: "W"},
    {value: "E", label: "E"},
];

const orderByList = [
    {label: "Admission No.", value: "adm_no"},
    {label: "ID No.", value: "id"},
    {label: "Stud Status", value: "stud_status"},
    {label: "Gross Income", value: "gross_income"},
    {label: "Dependent", value: "dependent"},
    {label: "MPCI", value: "mpci"},
    {label: "FPCI", value: "fpci"},
    {label: "Other Income", value: "other_income"},
    {label: "GHI Income", value: "ccc_income"},
];

function PrintApplicationDetail() {
    const [isOpen, setIsOpen] = useState(false);
    const [showModalExport, setShowModalExport] = useState(false);

    const handleSubmit = () => {
        // setShowModalExport(true);
    };
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    const [dataExercise, setDataExercise] = useState([]);
    const [academyYear, setAcademyYear] = useState(null);
    const [exercise, setExercise] = useState(null);
    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);
    const [award, setAward] = useState(null);
    const [awardType, setAwardType] = useState(null);
    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
    const [loadingListAward, setLoadingListAward] = useState(false);
    const [dataAdminNo, setDataAdminNo] = useState([]);
    const [adminNo, setAdminNo] = useState(null);
    const refTimeoutSearch = useRef(null);
    const [applicationStatus, setApplicationStatus] = useState(null);
    const [showRetrieve, setShowRetrieve] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState("");
    const [dataApplications, setDataApplications] = useState([]);
    const [studentDetail, setStudentDetail] = useState({});
    const refPDF = useRef();
    const refCSV = useRef();
    const headerRef = useRef();
    const tbl = useRef(null);
    const [orderOption, setOrderOption] = useState(null)
    const [loading, setLoading] = useState(false)

    //Export to Excel Function
    const exportToXlsx = useCallback(() => {
        const tableElement = tbl.current;
        const headerElement = headerRef.current;

        const extractTextContent = (element) => {
            if (!element) return [];
            const rows = [];
            const headingElements = element.querySelectorAll('h3, h5');
            headingElements.forEach(headingElement => {
                rows.push([headingElement.textContent.trim()]);
            });
            return rows;
        };

        const headerRows = extractTextContent(headerElement);

        const headerSheet = utils.aoa_to_sheet(headerRows);

        const tableSheet = utils.table_to_sheet(tableElement);

        const combinedSheet = utils.sheet_add_aoa(headerSheet, utils.sheet_to_json(tableSheet, {
            header: 1,
            skipHeader: true
        }), {origin: -1});

        const wb = utils.book_new();

        utils.book_append_sheet(wb, combinedSheet, "Report");

        writeFileXLSX(wb, "report-details.xlsx");
    }, [tbl, headerRef]);

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAwardType();
        handleGetListNYPRemarks();
    }, []);

    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        if (exercise?.value) handleGetListAdminNo();
    }, [exercise]);

    useEffect(() => {
        if (awardType?.value) handleGetListAward(awardType?.value);
    }, [awardType]);

    //Export File to PDF or Excel
    const handleExport = () => {
        let optionChecked = "";

        let pdfChecked = refPDF.current.checked;
        let csvChecked = refCSV.current.checked;
        if (pdfChecked) {
            optionChecked = "PDF";
        } else if (csvChecked) {
            optionChecked = "CSV";
        }

        if (optionChecked == "PDF") {
            const docTable = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: "a3",
                margin: [10, 10, 10, 10],
            });

            const headerElement = headerRef.current;
            const headerText = headerElement ? headerElement.innerText.split('\n') : [];

            let verticalOffset = 10;

            headerText.forEach((line, index) => {
                docTable.text(line, 10, verticalOffset);
                verticalOffset += 10;
            });

            autoTable(docTable, {
                html: "#my-table",
                startY: verticalOffset,
                willDrawCell: function (data) {
                    if (data.row.section === "head") {
                        data.cell.styles.fillColor = [9, 230, 42];
                    }
                },
            });

            docTable.save("report-details.pdf");
        } else if (optionChecked == "CSV") {
            exportToXlsx();
        }
    };
    //Function when Retrieve button pressed
    const handleRetrieve = async () => {
        setShowRetrieve(true);
        setLoading(true)
        try {
            const applications = await GetApplications(
                academyYear?.value,
                exercise?.value,
                adminNo?.value,
                applicationStatus?.value,
                verificationStatus?.value,
                awardType?.value,
                award?.value,
                orderOption?.value
            );

            const applicationData = applications?.data?.data || [];
            setDataApplications(applicationData);

            const modifiedItems = applicationData.map((item) => ({
                ...item,
                name: "",
            }));

            setDataApplications(modifiedItems);

            const itemsMap = modifiedItems.reduce((map, item) => {
                map[item.adm_no] = item;
                return map;
            }, {});

            const studentIds = applicationData.map((application) => application.adm_no);

            if (studentIds.length > 0) {
                // Helper function to split the array into chunks of 500
                const chunkArray = (arr, chunkSize) =>
                    arr.reduce((result, item, index) => {
                        const chunkIndex = Math.floor(index / chunkSize);
                        if (!result[chunkIndex]) result[chunkIndex] = [];
                        result[chunkIndex].push(item);
                        return result;
                    }, []);

                const studentChunks = chunkArray(studentIds, 500);

                // Process each chunk sequentially
                for (const chunk of studentChunks) {
                    const students_id = chunk.join(",");
                    const response = await postStudentList(students_id, false, "id_no", true);

                    response?.data?.data?.forEach((student) => {
                        const item = itemsMap[student.admNo];
                        if (item) {
                            getCodeList(item.rec_award_type, item.rec_award_code).then((response) => {
                                setDataApplications((prevItems) =>
                                    prevItems.map((prevItem) => {
                                        if (prevItem.adm_no === item.adm_no) {
                                            return {
                                                ...prevItem,
                                                rec_award_code_label: response?.data?.data?.[0]?.description
                                            };
                                        }
                                        return prevItem;
                                    })
                                );
                            })

                            setDataApplications((prevItems) =>
                                prevItems.map((prevItem) => {
                                    if (prevItem.adm_no === item.adm_no) {
                                        return {
                                            ...prevItem,
                                            name: student.name,
                                            adm_no: student.admNo,
                                            id_no: student.idNo,
                                            citizen: student.citizenDesc,
                                            course:
                                                student.courseList?.length > 0
                                                    ? student.courseList[0]?.courseName
                                                    : "-",
                                            studyStage:
                                                student.courseList?.length > 0
                                                    ? student.courseList[0]?.studyStage
                                                    : "-",
                                            courseCode:
                                                student.courseList?.length > 0
                                                    ? student.courseList[0]?.courseCode
                                                    : "-",
                                            studyStatus:
                                                student.courseList?.length > 0
                                                    ? student.courseList[0]?.studStatus
                                                    : "-",
                                            tutFeeLoan: student.tutFeeLoan,
                                            pr: student.pr,
                                            admDate: student.courseList?.length > 0
                                                ? student.courseList[0]?.admDate
                                                : "-",
                                            awardAmt: student?.studFinAssistList?.length > 0
                                                ? student?.studFinAssistList[0]?.amtAwarded
                                                : "-"
                                        };
                                    }
                                    return prevItem;
                                })
                            );
                        }
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    };
    const handleSearch = (searchFunction = null, value = "") => {
        clearTimeout(refTimeoutSearch.current);

        if (value) {
            refTimeoutSearch.current = setTimeout(() => {
                searchFunction(value);
            }, 500);
        }
    };
    const handleGetListAdminNo = async (search = "") => {
        try {
            setAdminNo(null);
            setDataAdminNo([]);

            const response = await getApplicationListByAcadYrExercise({
                acad_yr: academyYear?.value,
                exercise: exercise.value,
                adm_no: search,
                limit: 20
            });

            setDataAdminNo(
                response?.data?.data?.map((item) => ({
                    label: item?.adm_no,
                    value: item?.adm_no,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };
    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();
            const academyYears = response?.data?.data?.map((item) => ({
                label: item?.acad_yr,
                value: item?.acad_yr,
            }))
            const sortedAcademyYears = sortDescendingByKey(academyYears, "value");

            setDataAcademyYear(sortedAcademyYears);

        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };

    const handleGetListAward = async (codeType = "") => {
        setLoadingListAward(true);

        try {
            setAward(null);

            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setAwardType(null);

            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const [nypRemarkData, setNypRemarkData] = useState(null);

    const handleGetListNYPRemarks = async () => {
        try {
            const response = await getRemarkNYP(0, 0)

            setNypRemarkData(
                response?.data?.data?.map((item) => ({
                    label: item?.remark,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    return (
        <Layout currentNav={"reports"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Print Applicants Detail</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Print Applicants Detail</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>
                    <Form className={` d-flex flex-column gap-3 collapse-animation`}>
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        onChange={(value) => setAcademyYear(value)}
                                        options={dataAcademyYear}
                                        placeholder={`Academic Year`}
                                        isClearable={true}
                                        value={academyYear}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        isDisabled={!academyYear?.value}
                                        onChange={(value) => setExercise(value)}
                                        options={dataExercise}
                                        isClearable={true}
                                        value={exercise}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Adm. No.</Form.Label>
                                    <Select
                                        onChange={(value) => setAdminNo(value)}
                                        options={dataAdminNo}
                                        isClearable={true}
                                        onInputChange={(value) =>
                                            handleSearch(handleGetListAdminNo, value)
                                        }
                                        value={adminNo}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Verification Status</Form.Label>
                                    <Select
                                        options={VerificationStatus()}
                                        isClearable={true}
                                        onChange={(value) => setVerificationStatus(value)}
                                        value={verificationStatus}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Application Status</Form.Label>
                                    <Select
                                        isClearable
                                        backspaceRemovesValue={true}
                                        onChange={(value) => setApplicationStatus(value)}
                                        options={ApplicationStatus()}
                                        placeholder={`Application Status`}
                                        value={applicationStatus}
                                    />
                                </Form.Group>
                            </Col>
                            <Row>
                                <Col xs={6} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Award Type</Form.Label>
                                        <Select
                                            onChange={(value) => setAwardType(value)}
                                            options={dataAwardType}
                                            value={awardType}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Award</Form.Label>
                                        <Select
                                            isDisabled={!awardType?.value}
                                            onChange={(value) => setAward(value)}
                                            options={dataAward}
                                            value={award}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Col xs={4} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Order by</Form.Label>
                                    <Select
                                        options={orderByList}
                                        onChange={(selectedOption) => setOrderOption(selectedOption)}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className="mt-3">
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleRetrieve}
                                >
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {loading ?
                    (<div className={`p-3 bg-white shadow-sm border rounded border-1 text-center`}> Loading. .
                        . </div>) :
                    showRetrieve && (
                        <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                            <Row>
                                <Col xs={12}>
                                    <div className="text-end">
                                        <Button
                                            type="button"
                                            className="btn btn-primary ms-auto"
                                            onClick={() => setShowModalExport(true)}
                                        >
                                            Export
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <Row id="header-pdf" ref={headerRef}>
                                    <Col xs={12}>
                                        <h3 className="text-center mb-6">Applicants Detail Report</h3>
                                    </Col>
                                    <Col xs={4}>
                                        <h5>
                                            Academic Year: <span>{academyYear?.value}</span>
                                        </h5>
                                        <h5>
                                            Verification Status:{" "}
                                            <span>{verificationStatus?.label}</span>
                                        </h5>
                                        <h5>
                                            Application Status: <span>{applicationStatus?.label}</span>
                                        </h5>
                                    </Col>
                                    <Col xs={4}>
                                        <h5>
                                            Exercise: <span>{exercise?.label}</span>
                                        </h5>
                                        <h5>
                                            Award Type: <span>{awardType?.label}</span>
                                        </h5>
                                        <h5>
                                            Award: <span>{award?.label}</span>
                                        </h5>
                                    </Col>
                                </Row>
                                <Table responsive ref={tbl} id="my-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Adm No.
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                ID No
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Name
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Citizen
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                PR
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Course
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Study Stage
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Stud Status
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Course Code
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Adm Date
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                TFL
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                MPCI
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                {" "}
                                                GHI Income
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "150px"}}>
                                                Dependents
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "130px"}}>
                                                FPCI
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Application Status
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Application Status Remarks (NYP)
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Recommended Award Type
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Recommended Award Code
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Amount Awarded
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Verified By
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Verified Date
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Last Update ID
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{width: "300px"}}>
                                                Updated at
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {dataApplications?.map((item, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.DataCell>{item?.adm_no}</Table.DataCell>
                                                    <Table.DataCell>{item?.id_no}</Table.DataCell>
                                                    <Table.DataCell>{item?.name}</Table.DataCell>
                                                    <Table.DataCell>
                                                        {item?.citizen}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {item?.pr}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {item?.course}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.studyStage
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.studyStatus
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.courseCode
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.admDate
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.tutFeeLoan
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.mpci
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {item?.ccc_income}
                                                    </Table.DataCell>
                                                    <Table.DataCell>{item?.dependent}</Table.DataCell>
                                                    <Table.DataCell>{item?.fpci}</Table.DataCell>
                                                    <Table.DataCell> {item?.appln_status}</Table.DataCell>
                                                    <Table.DataCell>
                                                        {nypRemarkData.find((award) => award?.value === item?.remark)?.label || item?.remark}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {dataAwardType.find((award) => award?.value === item?.rec_award_type)?.label || item?.rec_award_type}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {item?.rec_award_code_label || item?.rec_award_code}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            item?.awardAmt
                                                        }
                                                    </Table.DataCell>
                                                    <Table.DataCell>{item?.verify_status_by}</Table.DataCell>
                                                    <Table.DataCell> {item?.verify_status_date}</Table.DataCell>
                                                    <Table.DataCell>{item?.last_upd_id}</Table.DataCell>
                                                    <Table.DataCell> {item?.updated_at}</Table.DataCell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}
                <CustomModal
                    show={showModalExport}
                    title={`Export`}
                    handleClose={() => setShowModalExport(false)}
                    modalSize={"md"}
                    withIcon={false}
                    description={
                        <div>
                            <div>
                                <b>Export as</b>
                            </div>
                            <div>
                                <FormCheck
                                    type="radio"
                                    name="export"
                                    label="PDF"
                                    value="pdf"
                                    id="pdf"
                                    ref={refPDF}
                                />
                                <FormCheck
                                    ref={refCSV}
                                    type="radio"
                                    name="export"
                                    label="CSV"
                                    value="csv"
                                    id="csv"
                                />
                            </div>
                        </div>
                    }
                    submitButtonText={`Export`}
                    action={handleExport}
                />
            </sgds-content-body>
        </Layout>
    );
}

export default PrintApplicationDetail;
