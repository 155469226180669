import {Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getCodeList} from "@/apis/sims/api";
import {
    postDownloadNewRecommendList,
    postDownloadNewRecommendListWithNewApplication,
    postDownloadNewRecommendListWithoutNewApplication,
} from "@/apis/applications/api";
import {getSchemeList} from "@/apis/preparation/setup-scheme/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import {sortExerciseList, sortDescendingByKey} from "@/components/utils/utils";
import {getAwardListWithParams} from "@/apis/preparation/setup-award/api";
import {getPCList} from "@/apis/preparation/setup-cspc/api";

export default function Download() {
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);
    const [dataDownload, setDataDownload] = useState(null);
    const [dataExercise, setDataExercise] = useState([]);
    const [dataScheme, setDataScheme] = useState([]);

    const [filename, setFilename] = useState(null);

    const [isErrorEntry , setIsErrorEntry] = useState(false);
    const [errorReportData, setErrorReportData] = useState([])

    const [academyYear, setAcademyYear] = useState(null);
    const [award, setAward] = useState(null);
    const [awardType, setAwardType] = useState(null);
    const [exerciseFrom, setExerciseFrom] = useState(null);
    const [exerciseTo, setExerciseTo] = useState(null);
    const [scheme, setScheme] = useState(null);

    const [loadingListAward, setLoadingListAward] = useState(false);

    const [downloadOption, setDownloadOptions] = useState("Download New");

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAwardType();
        handleGetListScheme();
    }, []);

    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        if (awardType?.value) handleGetListAward(awardType?.value);
    }, [awardType]);

    const formatToEightDigits = (num) => {
        return num.toString().padStart(8, '0');
      };


    const maskLastFour = (input) => {
    return input.slice(0, -4).replace(/./g, 'x') + input.slice(-4);
    };



    const downloadTemplate = async (contents) => {

        let data = `1~${0}~${scheme?.value}\n`;
        setIsErrorEntry(false)
        setErrorReportData([])




        let newData = [];

        if (contents?.data?.data?.length > 0) {
            data = `1~${contents?.data?.data?.length}~${scheme?.value}\n`;
            const items = contents?.data?.data;
            let quantum = 0;

            console.log(items)

            const promises = items.map(async(item) => {
                const response = await getAwardListWithParams(item?.rec_award_type, item?.rec_award_code);
                if (response?.data?.data?.length > 0) {
                    quantum = response?.data?.data[0]?.quantum;
                } else {
                    setIsErrorEntry(true)
                }

                const responsePC = await getPCList(item?.sims?.termPostCode,20,1);
                if (responsePC?.data?.data?.length > 0) {
                    item.csCode = responsePC?.data?.data?.[0]?.faas_cs_codes;
                } else {
                    setIsErrorEntry(true)
                    item.csCode = null
                }

                const awardDetail = await getCodeList(item?.rec_award_type, item?.rec_award_code);
                if (awardDetail?.data?.data?.length > 0) {
                    item.award_detail = awardDetail?.data?.data[0]
                } else {
                    item.award_detail = null
                }



                newData.push([
                    item?.csCode?.cs_code,
                    item?.csCode?.cs_name,
                    maskLastFour(item?.sims?.idNo),
                    item?.sims?.name,
                    item?.sims?.termAddr1,
                    item?.sims?.termAddr2,
                    item?.sims?.termAddr3,
                    item?.sims?.termUnitno,
                    item?.sims?.termPostCode,
                    item?.sims?.raceDesc,
                    item?.sims?.courseList?.[0]?.courseName,
                    item?.sims?.studEmail,
                    item?.sims?.handphone,
                    scheme?.institute_id,
                    item?.sims?.courseList?.[0]?.studyStage,
                    scheme?.institute_id+scheme?.year_offer+scheme?.month_offer,
                    item?.acad_yr,
                    item?.exercise,
                    item?.adm_no,
                    item?.sims?.idNo,
                    item?.rec_award_code,
                    item?.award_detail?.description,
                    quantum,
                    'criteria',
                    item?.mthly_income,
                    item?.mpci,
                    item?.sims?.studStatus,
                    item?.sims?.personalEmail,
                ]);





                data += `2~${item?.sims?.idNo}~${item?.sims?.name}~${scheme?.institute_id}~${scheme?.institution}~ ~ ~${item?.sims?.courseList?.[0]?.courseCode}~${item?.sims?.courseList?.[0]?.studyStage}~${item?.sims?.termAddr1}~${item?.sims?.termAddr2}~${item?.sims?.termUnitno}~${item?.sims?.termPostCode}~${item?.sims?.raceDesc}~${item?.csCode}~${quantum}~${item?.data_flag}~${item?.reason_for_changes}~${scheme?.value}\n`
                if (item?.rec_award_type !== null && item.csCode !== null) {
                } else {
                    setErrorReportData([...errorReportData, {
                        content: `2~${item?.sims?.idNo}~${item?.sims?.name}~${scheme?.institute_id}~${scheme?.institution}~ ~ ~${item?.sims?.courseList?.[0]?.courseCode}~${item?.sims?.courseList?.[0]?.studyStage}~${item?.sims?.termAddr1}~${item?.sims?.termAddr2}~${item?.sims?.termUnitno}~${item?.sims?.termPostCode}~${item?.sims?.raceDesc}~${item?.csCode}~${quantum}~${item?.data_flag}~${item?.reason_for_changes}~${scheme?.value}\n`

                    }])
                }

            })
            // Wait for all async operations to complete
            await Promise.all(promises);

            // data += `3~${formatToEightDigits(quantum)}~${Number(contents?.data?.data?.length)}~${formatToEightDigits(Number(quantum)*(Number(contents?.data?.data?.length)))}~${scheme?.value}\n`;
        } else {
            setIsErrorEntry(true)
            let data = {
                content: `NotFound~${scheme?.value}~${scheme?.institute_id}\n`
            }
            setErrorReportData([...errorReportData, data])

        }


        let headerTable = [
            "CO",
            "CO Name",
            "NRIC No. (masked except for last 4 digits)",
            "Student Name (as per NRIC)",
            "Blk-Hs-No",
            "Street-Name",
            "Building Name",
            "Floor-Unit",
            "Postal Code",
            "Race",
            "Course",
            "NYP Email",
            "Contact Number",
            "Institution",
            "Year of Study",
            "Scheme Code",
            "Academic Year of Award",
            "Exercise",
            "Adm No",
            "NRIC No",
            "Bursary Code",
            "Bursary Name",
            "Quantum",
            "Computed Income Range",
            "GHI",
            "PCI",
            "STUDSTATUS",
            "PERSONAL_EMAIL",
        ];

        console.log(newData);

        let wsData = [
            headerTable, ...newData
        ];

        console.log(wsData);
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Download List");

        XLSX.writeFile(wb, scheme?.institute_id+"-"+scheme?.value+"-"+academyYear?.value+"-"+exerciseFrom.value.toString().padStart(2, '0')+"-"+(scheme?.serial_no == null ? 1 : (Number(scheme?.serial_no)+1))+"l.xlsx");

        return;

    };

    const handleDownloadErrorFile = async () => {
        // errorReportData
        let data = `Error Report Data\n`;
        errorReportData?.map((errorData) => {
            data += errorData?.content
        })

        const blob = new Blob([data], { type: "text/plain" });

        // Generate a download link and click it programmatically
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // The name for the file
        a.download = "errorReportData.txt";
        document.body.appendChild(a);
        a.click();

        // Clean up
        a.remove();
        window.URL.revokeObjectURL(url);
        return;

    }


    const handleDownloadFile = async () => {
        try {
            let api = null;

            const params = {
                acad_yr: academyYear?.value,
                exercise_from: exerciseFrom?.value,
                exercise_to: exerciseTo?.value,
                rec_award_type: awardType?.value,
                rec_award_code: award?.value,
                institute_id: scheme?.institute_id,
                scheme_id: scheme?.value,
                last_upd_id: sessionStorage.getItem('staffId') ? sessionStorage.getItem('staffId') : process.env.REACT_APP_LAST_UPD_ID,
            };

            if (downloadOption === "Download New") {
                api = await postDownloadNewRecommendList(params);

                downloadTemplate(api);
            } else if (downloadOption === "Download again without New Applicants") {
                api = await postDownloadNewRecommendListWithoutNewApplication(params);
                downloadTemplate(api);
            } else if (downloadOption === "Download again with New Applicants") {
                api = await postDownloadNewRecommendListWithNewApplication(params);
                downloadTemplate(api);
            }


            if(api?.data?.data?.length === 0){
                toast.warning("No data found");
            }

            console.log(api);
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListAcademyYear = async () => {
        try {
            setAcademyYear(null);

            const response = await getAcademyYear();
            const academyYears = response?.data?.data?.map((item) => ({
                label: item?.acad_yr,
                value: item?.acad_yr,
            }))
            const sortedAcademyYears = sortDescendingByKey(academyYears, "value");

            setDataAcademyYear(sortedAcademyYears);

        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListAward = async (codeType = null) => {
        setLoadingListAward(true);

        try {
            setAward(null);

            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setAwardType(null);

            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExerciseFrom(null);
            setExerciseTo(null);

            const response = await getExercise(params);
            if (response?.data?.data?.length > 0 ) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListScheme = async () => {
        const params = {
            page: 0,
        };

        try {
            const response = await getSchemeList(params);

            console.log(response)
            setDataScheme(
                response?.data?.data?.map((item) => ({
                    ...item,
                    label: item?.scheme_id,
                    value: item?.scheme_id,
                    institute_id: item?.institute_id,
                    serial_no: item?.serial_no,
                    institution: item?.institution,
                    year_offer: item?.year_offer,
                    month_offer: item?.month_offer
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    return (
        <Layout currentNav={"processing"} toc={false}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
                <Breadcrumb.Item active>Download Recommended List</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Download Recommended List</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                  ? `${
                                      contentRef?.current?.scrollHeight == null
                                        ? "100%"
                                        : contentRef?.current?.scrollHeight
                                    }px`
                                  : "0px",
                              }}

                            onSubmit={() => console.log("a")}
                        >
                            <Row>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Academic Year <span className="text-danger">*</span></Form.Label>
                                        <Select
                                            onChange={(value) => setAcademyYear(value)}
                                            options={dataAcademyYear}
                                            placeholder={`Academic Year`}
                                            value={academyYear}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Exercise From <span className="text-danger">*</span></Form.Label>
                                        <Select
                                            isDisabled={!academyYear?.value}
                                            onChange={(value) => setExerciseFrom(value)}
                                            options={dataExercise}
                                            value={exerciseFrom}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Exercise To <span className="text-danger">*</span></Form.Label>
                                        <Select
                                            isDisabled={!academyYear?.value}
                                            onChange={(value) => setExerciseTo(value)}
                                            options={dataExercise}
                                            value={exerciseTo}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Award Type <span className="text-danger">*</span></Form.Label>
                                        <Select
                                            onChange={(value) => setAwardType(value)}
                                            options={dataAwardType}
                                            value={awardType}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Award <span className="text-danger">*</span></Form.Label>
                                        <Select
                                            isDisabled={!awardType?.value}
                                            onChange={(value) => setAward(value)}
                                            options={dataAward}
                                            value={award}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Scheme <span className="text-danger">*</span></Form.Label>
                                        <Select
                                            onChange={(value) => setScheme(value)}
                                            options={dataScheme}
                                            value={scheme}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className={`d-flex flex-column`}>
                                <Col>
                                    <h4 className={`text-blue fw-bold`}>Download Option</h4>
                                </Col>

                                <Col>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionRad"
                                            id="optionRad1"
                                            checked={downloadOption === "Download New" ?? false}
                                            onChange={() => setDownloadOptions("Download New")}
                                        />
                                        <label className="form-check-label" htmlFor="optionRad1">
                                            Download New
                                        </label>
                                    </div>
                                </Col>

                                <Col>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="optionRad2"
                                            checked={
                                                downloadOption ===
                                                "Download again without New Applicants" ?? false
                                            }
                                            onChange={() =>
                                                setDownloadOptions(
                                                    "Download again without New Applicants"
                                                )
                                            }
                                        />
                                        <label className="form-check-label" htmlFor="optionRad2">
                                            Download again without New Applicants
                                        </label>
                                    </div>
                                </Col>

                                <Col>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="optionRad3"
                                            checked={
                                                downloadOption ===
                                                "Download again with New Applicants" ?? false
                                            }
                                            onChange={() =>
                                                setDownloadOptions("Download again with New Applicants")
                                            }
                                        />
                                        <label className="form-check-label" htmlFor="optionRad3">
                                            Download again with New Applicants
                                        </label>
                                    </div>
                                </Col>
                            </Row>

                            <Row className={`gap-1`}>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Download filename</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setFilename(e.target.value)}
                                            placeholder={`ABC-DEF-2023-05-01I.txt`}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    Name the file as follows:
                                    <ul>
                                        <li>
                                            Institute ID-Scheme-Year (YYYY)-Month (MM)-Serial number
                                            (nn).txt
                                        </li>
                                        <li>Example: ABC-DEF-2023-05-01I.txt</li>
                                        <li>
                                            Serial number starts at 1 if null, otherwise increment.
                                        </li>
                                    </ul>
                                    {isErrorEntry ?
                                        <a
                                            href="javascript:void(0)"
                                            onClick={() => handleDownloadErrorFile()}
                                        >Download Error List</a>
                                    : " "}
                                </Col>
                            </Row>
                        </Form>

                        <div className="d-flex gap-4 justify-content-between">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                            >
                                {isOpen ? "Hide " : "Expand "}{" "}
                                {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                            </button>
                            <button
                                type="button"
                                disabled={
                                    !academyYear?.value ||
                                    !exerciseFrom?.value ||
                                    !exerciseTo?.value ||
                                    !awardType?.value ||
                                    !award?.value ||
                                    !scheme?.value
                                }
                                className="btn btn-primary"
                                onClick={handleDownloadFile}
                            >
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            </sgds-content-body>
        </Layout>
    );
}
