import axios from 'axios'
import {getJwt} from "@/apex";

// URL for whitelist in the API Call
var whiteList = [
    "https://faas-backend.deeeplabs.com",
    "https://nyp-sfa-be-apps-uat.stg.e01.app.gov.sg",
    "https://nyp-sfa-be-homes-uat.stg.e01.app.gov.sg",
    "https://public-stg.api.gov.sg",
    "https://public.api.gov.sg"
]

//APEX API CALL
export function apexAxiosCall(url, params, method = "POST") {
    if (whiteList.some((whitelistedUrl) => url.startsWith(whitelistedUrl))) {
        return getJwt(params, method).then(async (resp) => {
            if (method == "POST") {
                // NYP
                return await axios
                    .post(url, params, {
                            headers: {
                                "x-apex-jwt": resp,
                            },
                        }
                    )
                    .then((responseAPI) => {
                        return responseAPI
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                return await axios({
                    headers: {
                        "x-apex-jwt": resp,
                    },
                    url: url,
                    method: method,
                    data: params,
                })
            }

        });
    }
}

// Non-APEX Api Call
export function apiCall(url, params, method = "POST") {
    if (whiteList.some((whitelistedUrl) => url.startsWith(whitelistedUrl))) {
        if (process.env.REACT_APP_ENV_API == "APEX") {
            return apexAxiosCall(url, params, method)
        } else {
            return axios({
                url: url,
                method: method,
                data: params,
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_FAAS_AUTH_API_KEY}`
                },
                maxRedirects: 0,
            })
        }
    }
}
