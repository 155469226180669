import {Button,} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useState} from "react";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import {deleteAward, getAwardList, postAward, putAward,} from "@/apis/preparation/setup-award/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import SortableTable from "@/components/SortableTable";
import {getCodeList} from "@/apis/sims/api";
import Select from "react-select";

export default function AwardDetail() {
    const [data, setData] = useState([]);

    const [header, setHeader] = useState([
        {key: 'index', label: '#', required: false},
        {key: 'award_type', label: 'Award Type', required: true},
        {key: 'award', label: 'Award', required: true},
        {key: 'quantum', label: 'Quantum', required: true},
        {key: 'action', label: '', required: false},
    ]);

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [selectedId, setSelectedId] = useState(null);

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [isEditing, setIsEditing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);

    const [awardTypeOptions, setAwardTypeOptions] = useState([]);
    const [selectedAwardTypeOption, setSelectedAwardTypeOption] = useState(null);
    const [awardOptions, setAwardOptions] = useState([]);
    const [selectedAwardOption, setSelectedAwardOption] = useState(null);

    const [awardTypes, setAwardTypes] = useState([]);

    const handleAddRow = () => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setData((prevArr) => {
            const newObj = {
                award_type: null,
                award: null,
                quantum: null,
                new_data: true
            };

            return [newObj, ...prevArr];
        });

        setEditArr((prevEditArr) => {
            return [false, ...prevEditArr];
        });

        setIsEditing(true);
        setIsDisabled(true)
    };

    const handleEdit = (index) => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
        setIsDisabled(true)
    };

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
        setIsDisabled(false)
        getAwards();
    }

    const handleSubmitEdit = (index, awardID) => {
        if (!data[index].award_type || !data[index].award || !data[index].quantum) {
            toast.error("Please fill out the required fields")
            return false
        }

        const awardData = {
            award_type: data[index].award_type,
            award: data[index].award,
            quantum: data[index].quantum,
        };
        //submit data
        if (awardID) {
            //update
            putAward(awardID, awardData)
                .then((response) => {

                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getAwards();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                        setIsDisabled(false)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postAward(awardData)
                .then((response) => {

                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getAwards();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                        setIsDisabled(false)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deleteAward(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success("Record deleted successfully");
                        getAwards();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);

            setIsEditing(false)

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const getAwards = async () => {
        try {
            const response = await getAwardList(itemsPerPage, currentPage);
            const awards = response?.data?.data || [];
            const updatedAwards = await Promise.all(awards.map(async (award) => {
                const descriptionResponse = await getCodeList(award.award_type, award.award);
                const description = descriptionResponse?.data?.data?.[0]?.description || "-";
                return {...award, awardDescription: description};
            }));

            setData(updatedAwards);
            setCurrentPage(response?.data?.currentPage);
            setTotalPages(response?.data?.totalPage);
            setIsNextPage(response?.data?.nextPage);

            const newArr = Array.from({length: updatedAwards.length}).fill(true);
            setEditArr(newArr);

        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    const renderCellContent = (row, rowIndex, key) => (
        editArr[rowIndex] ? (
            key === "award_type" ? (
                awardTypes?.find(type => type.code === row[key])?.description || "-"
            ) : key === "award" ? (
                row["awardDescription"]
            ) : (row[key])
        ) : key === "award_type" ? (
            <Select menuPortalTarget={document.body} options={awardTypeOptions}
                    value={awardTypeOptions?.find(option => option.value === row[key])}
                    onChange={(option) => {
                        handleChange(rowIndex, key, option.value);
                        setSelectedAwardTypeOption(option)
                    }}/>
        ) : key === "award" ? (
            <Select menuPortalTarget={document.body} options={awardOptions}
                    value={awardOptions?.find(option => option.value === row[key])}
                    onChange={(option) => {
                        handleChange(rowIndex, key, option.value);
                        setSelectedAwardOption(option)
                    }}/>
        ) : (
            <input
                type={"text"}
                value={row[key]}
                onChange={(e) => handleChange(rowIndex, key, e.target.value)}
            />
        )
    );

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.id)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        {` `}
                        Save
                    </button>
                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>


            ) : (
                <button
                    disabled={isDisabled}
                    className={`btn-clear`}
                    onClick={() => {
                        handleEdit(rowIndex);
                        setSelectedAwardTypeOption({
                            value: row?.award_type,
                            label: row?.award
                        })
                    }}
                >
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )}

            <button
                disabled={isDisabled}
                className={`btn-clear`}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.id ?? null);
                    setSelectedIndex(rowIndex);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setSelectedAwardOption(null);

            const response = await getCodeList(codeType);

            setAwardTypeOptions(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );

            setAwardTypes(response?.data?.data)
        } catch (error) {
            return {
                value: error?.message,
            }
        }
    };

    const handleGetListAward = async () => {
        try {
            const response = await getCodeList(selectedAwardTypeOption.value);

            setAwardOptions(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            return {
                value: error?.message,
            }
        }
    };

    useEffect(() => {
        handleGetListAwardType()
    }, [])

    useEffect(() => {
        handleGetListAward()
    }, [selectedAwardTypeOption]);

    useEffect(() => {
        getAwards();
    }, [currentPage]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Setup Award Detail</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Setup Award Detail</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Button
                    variant={`outline-dark`}
                    onClick={handleAddRow}
                    className={`d-flex gap-3`}
                >
                    <i className="bi bi-plus-circle"></i>
                    Add
                </Button>
            </sgds-content-area-bottom>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable headers={header} data={data} renderActionButtons={renderActionButtons}
                                   renderCellContent={renderCellContent} setData={setData}/>

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
            {" "}
                            Page {currentPage} of {totalPages}{" "}
          </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </sgds-content-body>
            <CustomModal
                show={show}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />
        </Layout>
    );
}
