import {createUrlWithParams} from "@/components/utils/utils";
import {apiCall} from "@/utils/apiCall";

//GET Application List
export async function getApplicationListByAcadYrExercise(paramsURL = null) {
    if (paramsURL) {
        const {
            acad_yr,
            exercise,
            adm_no,
            limit
        } = paramsURL;

        let params = {};
        if (acad_yr !== null && acad_yr !== undefined) {
            params = {
                ...params,
                acad_yr: acad_yr,
            };
        }
        if (exercise !== null && exercise !== undefined) {
            params = {
                ...params,
                exercise: exercise,
            };
        }
        if (adm_no !== null && adm_no !== undefined) {
            params = {
                ...params,
                adm_no: adm_no,
            };
        }

        if (limit !== null && limit !== undefined) {
            params = {
                ...params,
                limit: limit,
            };
        }

        params = {
            ...params,
        };
        let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
        const url = createUrlWithParams(baseUrl, params);
        return apiCall(url, {}, "GET");
    } else {
        return apiCall(`${process.env.REACT_APP_API_URL}/applications`, {}, "GET");
    }
}

//GET Application List
export async function getApplicationList(paramsURL = null) {
    if (paramsURL) {
        const {
            acad_yr,
            exercise,
            adm_no,
            appln_status,
            verify_status,
            rec_award_type,
            rec_award_code,
            order_by_field,
            page = 0,
            limit = 0,
        } = paramsURL;

        let params = {};
        if (acad_yr !== "" && acad_yr !== null && acad_yr !== undefined) {
            params = {
                ...params,
                acad_yr: acad_yr,
            };
        }
        if (exercise !== "" && exercise !== null && exercise !== undefined) {
            params = {
                ...params,
                exercise: exercise,
            };
        }
        if (adm_no !== "" && adm_no !== null && adm_no !== undefined) {
            params = {
                ...params,
                adm_no: adm_no,
            };
        }
        if (appln_status !== "" && appln_status !== null && appln_status !== undefined) {
            params = {
                ...params,
                appln_status: appln_status,
            };
        }
        if (verify_status !== "" && verify_status !== "" && verify_status !== undefined) {
            params = {
                ...params,
                verify_status: verify_status,
            };
        }
        if (rec_award_type !== "" && rec_award_type !== null && rec_award_type !== undefined) {
            params = {
                ...params,
                rec_award_type: rec_award_type,
            };
        }
        if (rec_award_code !== "" && rec_award_code !== null && rec_award_code !== undefined) {
            params = {
                ...params,
                rec_award_code: rec_award_code,
            };
        }

        if (order_by_field !== "" && order_by_field !== null && order_by_field !== undefined) {
            params = {
                ...params,
                orderByField: order_by_field,
            };
        }

        if (page !== "" && page !== null && page !== undefined) {
            params = {
                ...params,
                page: page,
            };
        }

        if (limit !== "" && limit !== null && limit !== undefined) {
            params = {
                ...params,
                limit: limit,
            };
        }

        params = {
            ...params,
            isAnalytic: true,
            appln_status_not_equals: "D",
        };
        let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
        const url = createUrlWithParams(baseUrl, params);
        return apiCall(url, {}, "GET");
    } else {
        return apiCall(`${process.env.REACT_APP_API_URL}/applications`, {}, "GET");
    }
}

//GET Application Detail
export function getApplicationDetail(acadyear, exercise, admno) {

    let params = {};
    if (acadyear !== null && acadyear !== "" && acadyear !== undefined) {
        params = {
            ...params,
            acad_yr: acadyear,
        };
    }
    if (exercise !== null && exercise !== "" && exercise !== undefined) {
        params = {
            ...params,
            exercise: exercise,
        };
    }
    if (admno !== null && admno !== "" && admno !== undefined) {
        params = {
            ...params,
            adm_no: admno,
        };
    }


    params = {
        ...params,
        limit: 0,
        appln_status_not_equals: "D",
    };
    let baseUrl = process.env.REACT_APP_API_URL + `/applications/details`;
    const url = createUrlWithParams(baseUrl, params);
    return apiCall(url, {}, "GET");


}

//POST Application Detail
export function submitApplicationDetail(params) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications`,
        params,
        "POST"
    );
}

//PATCH Application Detail
export function editApplicationDetail(params) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications`,
        params,
        "PATCH"
    );
}

//POST Transfer Application Exercise
export function transferApplicationExercise(params) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications/transfers/anothers/exercises`,
        params,
        "POST"
    );
}

//GET Detail Application Log
export function getDetailApplicationLog(ACAD_YR, EXERCISE, ADM_NO) {
    let params = {};
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== null && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }
    if (ADM_NO !== null && ADM_NO !== undefined) {
        params = {
            ...params,
            adm_no: ADM_NO,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications/details/logs`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}

//GET Detail Parent Log
export function getApplicationParentLog(ACAD_YR, EXERCISE, ADM_NO) {
    let params = {};
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== null && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }
    if (ADM_NO !== null && ADM_NO !== undefined) {
        params = {
            ...params,
            adm_no: ADM_NO,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications/parents/logs`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}

//GET Detail Sibling Log
export function getApplicationSiblingLog(ACAD_YR, EXERCISE, ADM_NO) {
    let params = {};
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== null && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }
    if (ADM_NO !== null && ADM_NO !== undefined) {
        params = {
            ...params,
            adm_no: ADM_NO,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications/siblings/logs`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}

//GET Scholarship
export function getScholarship(ACAD_YR, EXERCISE, ADM_NO) {
    let params = {};
    if (ACAD_YR !== "" && ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== "" && EXERCISE !== null && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }
    if (ADM_NO !== "" && ADM_NO !== null && ADM_NO !== undefined) {
        params = {
            ...params,
            adm_no: ADM_NO,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications/scholarships`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}

//POST Scholarship
export function upsertScholarship(data) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications/scholarships`,
        data,
        "POST"
    );
}

//DELETE Scholarship
export function deleteScholarship(id) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications/scholarships/${id}`,
        {},
        "DELETE"
    );
}

//POST Application Status
export function getDSpecialDetails(data) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications/specials/statuses`,
        data,
        "POST"
    );
}

//GET Scholarship Log
export function getScholarshipLog(ACAD_YR, EXERCISE, ADM_NO) {
    let params = {};
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== null && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }
    if (ADM_NO !== null && ADM_NO !== undefined) {
        params = {
            ...params,
            adm_no: ADM_NO,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications/scholarships/logs`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}
