import {Button} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useState} from "react";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import UploadModal from "@/components/UploadModal";
import CspcMenu from "@/components/Preparation/SetupCspc/Menu";
import * as XLSX from "xlsx";
import {deleteCS, getCSList, postAppendCS, postCS, postOverwriteCS, putCS,} from "@/apis/preparation/setup-cspc/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import SortableTable from "@/components/SortableTable";
import moment from "moment-timezone";

export default function ConstituencyCode() {
    const [data, setData] = useState([
        {
            cs_code: null,
            cs_name: null,
            tel1: null,
            tel2: null,
            fax: null,
        },
    ]);

    const [header, setHeader] = useState([
        {key: "index", label: "#", required: false},
        {key: "cs_code", label: "Code", required: true},
        {key: "cs_name", label: "Name", required: true},
        {key: "tel1", label: "Tel 1", required: false},
        {key: "tel2", label: "Tel 2", required: false},
        {key: "fax", label: "Fax", required: false},
        {key: "action", label: null, required: false},
    ]);

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [showUpload, setShowUpload] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const [selectedId, setSelectedId] = useState(null);

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [isEditing, setIsEditing] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false)

    const [search, setSearch] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const [isShowErrorUpload , setIsShowErrorUpload] = useState(false);
    const [errorReportData, setErrorReportData] = useState([])
    const [excelContent, setExcelContent] = useState([])

    const postOverwrite = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setExcelContent(jsonData)

            postOverwriteCS(jsonData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                            setIsShowErrorUpload(true)
                            setErrorReportData(response?.response?.data)

                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        setIsShowErrorUpload(false)
                        setErrorReportData([])

                        toast.success(`Record updated successfully `);
                        getCodes();
                        setShowUpload(false);
                        setSelectedFile(null)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const postAppend = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setExcelContent(jsonData)

            postAppendCS(jsonData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                            setIsShowErrorUpload(true)
                            setErrorReportData(response?.response?.data)

                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        setIsShowErrorUpload(false)
                        setErrorReportData([])

                        toast.success(`Record updated successfully `);
                        getCodes();
                        setShowUpload(false);
                        setSelectedFile(null)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const downloadErrorReport = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        if (errorReportData?.message == "cannot overwrite or delete, CS code is in use") {
            sheet1.push(["cs_code", "cs_name", "error message"]);

            excelContent?.forEach((errorData) => {
                const match = errorReportData?.data?.find(
                    (reportData) =>
                        errorData.cs_code === reportData.cs_code
                );

                if (match) {
                    sheet1.push([errorData?.cs_code, errorData?.cs_name, errorReportData?.message]);
                }
            });


        } else {
            sheet1.push(["value", "error_message"]);

            errorReportData?.data?.map((errorData) => {
                sheet1.push([errorData.value, errorData.msg])
            })
        }

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        XLSX.writeFile(wb, "error_import_cscodes_"+moment(Date.now()).tz("Asia/Singapore").format("YYYY_MM_DD_HH_mm_ss") +".xlsx");

    }

    const handleAddRow = () => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setData((prevArr) => {
            const newObj = {
                cs_code: null,
                cs_name: null,
                tel1: null,
                tel2: null,
                fax: null,
            };
            return [newObj, ...prevArr];
        });

        setEditArr((prevEditArr) => {
            return [false, ...prevEditArr];
        });

        setIsEditing(true);
        setIsDisabled(true)
    };

    const handleEdit = (index) => {
        toast.dismiss();
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
        setIsDisabled(true)
    };

    const handleSubmitEdit = (index, CSID) => {
        if (!data[index].cs_code || !data[index].cs_name) {
            toast.error("Please fill out the required fields");
            return false;
        }

        const CSData = {
            cs_code: data[index].cs_code,
            cs_name: data[index].cs_name,
            tel1: data[index].tel1,
            tel2: data[index].tel2,
            fax: data[index].fax,
        };

        //submit data
        if (CSID) {
            //update
            putCS(CSID, CSData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                        setIsDisabled(false)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postCS(CSData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                        setIsDisabled(false)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deleteCS(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success("Record deleted successfully");
                        getCodes();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);

            setIsEditing(false);

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const getCodes = () => {
        getCSList(search, itemsPerPage, currentPage)
            .then((response) => {
                setData(response?.data.data);
                setCurrentPage(response?.data?.currentPage);
                setTotalPages(response?.data?.totalPage);
                setIsNextPage(response?.data?.nextPage);

                const newArr = Array.from({length: response?.data.data.length}).fill(
                    true
                );

                setEditArr(newArr);

                setTotalPages(response?.data?.totalPage);
                setIsNextPage(response?.data?.nextPage);

            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
        getCodes()
    }

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.id)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        Save
                    </button>

                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>
            ) : (
                <button className={`btn-clear`}
                        disabled={isEditing}
                        onClick={() => handleEdit(rowIndex)}>
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )}

            <button
                className={`btn-clear`}
                disabled={rowIndex == 0 ? false : isEditing}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.id ?? null);
                    setSelectedIndex(rowIndex);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) =>
        editArr[rowIndex] ? (
            row[key]
        ) : (
            <input
                type={"text"}
                value={row[key]}
                maxLength={key === "cs_code" ? 2 : key === "cs_name" ? 48 : undefined}
                onChange={(e) => {
                    handleChange(rowIndex, key, e.target.value);
                }}
            />
        );

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDownloadTemplate = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        sheet1.push(["cs_code", "cs_name", "tel1", "tel2", "fax"]);

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        XLSX.writeFile(wb, "import_CSCode_template.xlsx");
    }

    useEffect(() => {
        getCodes();
    }, [search, currentPage]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Constituency Secretariat Code</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Constituency Secretariat Code</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <div className="d-flex gap-3">
                    <Button
                        variant={`outline-dark`}
                        onClick={handleAddRow}
                        className={`d-flex gap-3`}
                    >
                        <i className="bi bi-plus-circle"></i>
                        Add
                    </Button>

                    <Button
                        variant={`outline-dark`}
                        onClick={() => setShowUpload(true)}
                        className={`d-flex gap-3`}
                    >
                        <i className="bi bi-plus-circle"></i>
                        Upload new file
                    </Button>
                </div>
            </sgds-content-area-bottom>

            <div className="my-3">
                <CspcMenu activeMenu="cspc_code"/>
            </div>

            <div className="input-group mb-3 w-25">
                <input
                    type="text"
                    className="form-control border-end-0"
                    placeholder="Filter by code"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <span
                    className="input-group-text"
                    style={{background: "none", borderLeft: "none"}}
                >
          <i className="bi-sliders"></i>
        </span>
            </div>

            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable
                        headers={header}
                        data={data}
                        renderActionButtons={renderActionButtons}
                        renderCellContent={renderCellContent}
                        setData={setData}
                    />

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
              {" "}
                            Page {currentPage} of {totalPages}{" "}
            </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </sgds-content-body>
            <CustomModal
                show={show}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />

            <UploadModal
                show={showUpload}
                handleClose={() => setShowUpload(false)}
                title={`Upload Constituency Secretariat Code`}
                onFileSelect={handleFileSelect}
                selectedFile={selectedFile}
                actionOverwrite={postOverwrite}
                actionAppend={postAppend}
                handleDownloadTemplate={handleDownloadTemplate}
                downloadErrorReport={downloadErrorReport}
                isShowError={isShowErrorUpload}

            />
        </Layout>
    );
}
