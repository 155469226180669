// src/authConfig.js
// Microsoft Azure AD configuration
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID, // Or 'common' for multi-tenant apps
    redirectUri: process.env.REACT_APP_REDIRECT_AUTH_URL, // Your redirect URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to true if you're having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read", "Files.ReadWrite.All", "Sites.ReadWrite.All"],
};

export const tokenRequest = {
  scopes: ["api://" + process.env.REACT_APP_CLIENT_ID + "/access_as_user"],
};
