import React, {useCallback, useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, FormCheck, Row, Table,} from "@govtechsg/sgds-react";
import Select from "react-select";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getCodeList, getCodeTypeSasList} from "@/apis/sims/api";
import CustomModal from "@/components/CustomModal";
import {GetShortlistApplicants} from "@/apis/reports/api";

import {getApplicationList} from "@/apis/app-records/maintenance/api";

import jsPDF from "jspdf";
import {utils, writeFileXLSX} from "xlsx";
import autoTable from "jspdf-autotable";
import {toast} from "react-toastify";
import {postStudentList} from "@/apis/app-records/verification/api";
import {sortDescendingByKey, sortExerciseList} from "@/components/utils/utils";

const options = [{value: "CREATED_AT", label: "CREATED_AT"}];

function PrintShortlistedApplicants() {
    const [showRetrieve, setShowRetrieve] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [academyYear, setAcademyYear] = useState({
        label: new Date().getFullYear(),
        value: new Date().getFullYear(),
    });
    const [exercise, setExercise] = useState({label: "", value: ""});
    const [award, setAward] = useState({label: "", value: ""});
    const [awardType, setAwardType] = useState({label: "", value: ""});
    const [orderByField, setOrderByField] = useState("CREATED_AT");
    const [shortlistedPrint, setShortlistedPrint] = useState([]);
    const [applicationDetail, setApplicationDetail] = useState([]);
    const orderByList = [
        {label: "Admission No.", value: "adm_no"},
        {label: "ID No.", value: "id"},
        {label: "Course Code", value: "course_code"},
        {label: "Stud Status", value: "stud_status"},
        {label: "Gross Income", value: "gross_income"},
        {label: "Dependent", value: "dependent"},
        {label: "MPCI", value: "mpci"},
        {label: "FPCI", value: "fpci"},
        {label: "Other Income", value: "other_income"},
        {label: "GHI Income", value: "ccc_income"},
    ];

    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [dataExercise, setDataExercise] = useState([]);
    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);

    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
    const [loadingListAward, setLoadingListAward] = useState(false);
    const [showModalExport, setShowModalExport] = useState(false);
    const [dataApplications, setDataApplications] = useState(null);

    const refPDF = useRef();
    const refCSV = useRef();
    const headerRef = useRef();
    const tbl = useRef(null);
    const courseTableRef = useRef(null);
    const [totalData, setTotalData] = useState(0);

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const headerRefs = useRef([]);
    const tbls = useRef([]);
    const courseTableRefs = useRef([]);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const getAwardCodeDesc = async (awardCode) => {
        const resp = await getCodeTypeSasList("", awardCode);
        return resp?.data?.data?.[0]?.description;
    }

    //retrieve all datas
    const handleSubmit = async () => {
        try {
            const data = await getApplicationList({
                acad_yr: academyYear?.value,
                exercise: exercise?.value,
                rec_award_type: awardType?.value,
                rec_award_code: award?.value,
                order_by_field: orderByField?.value,
            });

            setShowRetrieve(true);

            setTotalData(data?.data?.totalData);

            headerRefs.current = Array(data?.data?.totalData).fill(null);
            tbls.current = Array(data?.data?.totalData).fill(null);
            courseTableRefs.current = Array(data?.data?.totalData).fill(null);

            const handleAwardCodesForItems = async (items) => {
                const modifiedItems = [];
                for (const item of items) {
                    const desc = await getAwardCodeDesc(item?.rec_award_code); // Runs sequentially
                    modifiedItems.push({
                        ...item,
                        student_detail: {},
                        rec_award_code_description: desc,
                    });
                }
                return modifiedItems;
            };


            const modifiedItems = await handleAwardCodesForItems(data?.data?.data);

            setShortlistedPrint(modifiedItems);

            const itemsMap = modifiedItems.reduce((map, item) => {
                map[item.adm_no] = item;
                return map;
            }, {});

            const students_id = data?.data?.data
                ?.map((application) => application.adm_no)
                .join(",");

            if (students_id) {
                postStudentList(students_id).then((response2) => {
                    response2?.data?.data.forEach((student) => {
                        const item = itemsMap[student.admNo];
                        if (item) {
                            setShortlistedPrint((prevItems) =>
                                prevItems.map((prevItem) => {
                                    if (prevItem.adm_no === item.adm_no) {
                                        return {
                                            ...prevItem,
                                            student_detail: student,
                                        };
                                    }
                                    return prevItem;
                                })
                            );
                        }
                    });
                });

            }
        } catch (error) {
            console.log(error);
            toast.error("Unable to get data");
        }
    };

    useEffect(() => {
        headerRefs.current = Array(totalData).fill(null);
        tbls.current = Array(totalData).fill(null);
        courseTableRefs.current = Array(totalData).fill(null);
    }, [totalData]);

    const exportToXlsx = useCallback(() => {
        const headerElement = headerRefs.current[selectedIndex];
        const mainTable = tbls.current[selectedIndex];
        const courseTable = courseTableRefs.current[selectedIndex];

        const extractTextContent = (element) => {
            if (!element) return [];
            const rows = [];
            const headingElements = element.querySelectorAll("h3, h5, h6");
            headingElements.forEach((headingElement) => {
                rows.push([headingElement.textContent.trim()]);
            });
            return rows;
        };

        const headerRows = extractTextContent(headerElement);

        const headerSheet = utils.aoa_to_sheet(headerRows);

        const data = [
            {
                Key: 'Name',
                Value: `${shortlistedPrint?.[selectedIndex]?.student_detail?.name ?? "-"} (${shortlistedPrint?.[selectedIndex]?.adm_no})`
            },
            {
                Key: 'Course Code',
                Value: shortlistedPrint?.[selectedIndex]?.student_detail?.courseList?.[0]?.courseCode ?? "-"
            },
            {
                Key: 'Study Stage',
                Value: shortlistedPrint?.[selectedIndex]?.student_detail?.courseList?.[0]?.studyStage ?? "-"
            },
            {Key: 'Address TERM 1', Value: shortlistedPrint?.[selectedIndex]?.student_detail?.termaddr1 ?? "-"},
            {Key: 'Address TERM 2', Value: shortlistedPrint?.[selectedIndex]?.student_detail?.termaddr2 ?? "-"},
            {Key: 'Address TERM 3', Value: shortlistedPrint?.[selectedIndex]?.student_detail?.termaddr3 ?? "-"},
            {Key: 'Tel 1', Value: shortlistedPrint?.[selectedIndex]?.student_detail?.termtel ?? "-"},
            {Key: 'Tel 2', Value: shortlistedPrint?.[selectedIndex]?.student_detail?.termtel_2 ?? "-"},
            {Key: 'HP', Value: shortlistedPrint?.[selectedIndex]?.student_detail?.handphone ?? "-"},
            {Key: 'Gross Mthly Income', Value: `$${shortlistedPrint?.[selectedIndex]?.gross_income ?? "-"}`},
            {Key: 'Gross Household Income', Value: `$${shortlistedPrint?.[selectedIndex]?.ccc_income ?? "-"}`},
            {Key: 'MPCI', Value: `$${shortlistedPrint?.[selectedIndex]?.mpci ?? "-"}`},
            {Key: 'FPCI', Value: `$${shortlistedPrint?.[selectedIndex]?.fpci ?? "-"}`},
            {Key: 'Special Details', Value: shortlistedPrint?.[selectedIndex]?.SPECIAL_DETAILS ?? "-"},
            {
                Key: 'Entry Qual',
                Value: shortlistedPrint?.[selectedIndex]?.student_detail?.courseList?.[0]?.entryQual ?? "-"
            },
            {
                Key: 'CCA Grade (Points)',
                Value: shortlistedPrint?.[selectedIndex]?.student_detail?.CCAList?.length > 0
                    ? shortlistedPrint?.[selectedIndex].student_detail.CCAList.reduce((sum, cca) => sum + parseInt(cca.ccaPoint), 0)
                    : "-"
            },
            {
                Key: 'No. of families',
                Value: Number(shortlistedPrint?.[selectedIndex]?.parent?.length) + Number(shortlistedPrint?.[selectedIndex]?.sibling?.length)
            },
        ]

        shortlistedPrint?.[selectedIndex]?.parent?.forEach((parent, index) => {
            data.push({
                Key: `Parent ${index + 1}`,
                Value: `${parent.relationship === 'M' ? 'Mother' : 'Father'} (${parent.age ?? "-"})`
            });
        });
        shortlistedPrint?.[selectedIndex]?.sibling?.forEach((sibling, index) => {
            data.push({Key: `Sibling ${index + 1}`, Value: `${sibling.relationshipDesc} (${sibling.age})`});
        });

        const infoSheet = utils.json_to_sheet(data, {header: ["Key", "Value"], skipHeader: true});

        const combinedSheet = utils.sheet_add_aoa(
            headerSheet,
            utils.sheet_to_json(infoSheet, {header: 1, skipHeader: true}),
            {origin: -1}
        );

        const wb = utils.book_new();

        utils.book_append_sheet(wb, combinedSheet, "Applicant Data");

        const wsCourse = utils.table_to_sheet(courseTable);
        utils.book_append_sheet(wb, wsCourse, "Course Data");

        writeFileXLSX(wb, "shorlisted-applicants.xlsx");
    }, [tbls, selectedIndex]);

    const handleExport = () => {
        let optionChecked = "";

        let pdfChecked = refPDF.current.checked;
        let csvChecked = refCSV.current.checked;
        if (pdfChecked) {
            optionChecked = "PDF";
        } else if (csvChecked) {
            optionChecked = "CSV";
        }

        if (optionChecked == "PDF") {
            const doc = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: "a3",
                margin: [10, 10, 10, 10],
            });

            //header
            doc.setFontSize(16);
            doc.setTextColor(0, 0, 0);
            doc.text("Shortlisted Applicants Report", 105, 20, {align: "center"});

            doc.setFontSize(14);
            doc.text(
                `Shortlisted Students for ${shortlistedPrint?.[selectedIndex]?.rec_award_code == null ? "-" : shortlistedPrint?.[selectedIndex]?.rec_award_code} for ${
                    shortlistedPrint?.[selectedIndex]?.acad_yr ?? "-"
                } ${shortlistedPrint?.[selectedIndex]?.exercise ? (` Exercise ${shortlistedPrint?.[selectedIndex]?.exercise}`) : null}`,
                105,
                30,
                {align: "center"}
            );

            doc.setFontSize(12);
            doc.text(
                `Quantum: ${shortlistedPrint?.[selectedIndex]?.quantum ?? ""}`,
                20,
                50
            );

            doc.text(`Eligibility Criteria: ${shortlistedPrint?.[selectedIndex]?.criteria?.criteria ?? ""}`, 20, 60);

            doc.setFontSize(12);
            doc.text("Notes", 20, 80);
            doc.text(
                "MPCI: Monthly Per Capital Income (Gross Mthly Income / No of Dependents in Household)",
                20,
                90
            );
            doc.text(
                "FPCI: Family Per Capital Income (Gross Mthly Income * 12 / No of Dependents in Household)",
                20,
                100
            );
            doc.text(
                `Recommended Award Type:${shortlistedPrint?.[selectedIndex]?.rec_award_type} ${shortlistedPrint?.[selectedIndex]?.rec_award_type ? (`(${awardTypeList.find(option => option.value === shortlistedPrint?.[selectedIndex]?.rec_award_type)?.label})`) : "-"}`,
                20,
                110
            );
            doc.text(
                `Recommended Award Code:${shortlistedPrint?.[selectedIndex]?.rec_award_code} ${shortlistedPrint?.[selectedIndex]?.rec_award_code ? shortlistedPrint?.[selectedIndex]?.rec_award_code_description : "-"}`,
                20,
                120
            );
            doc.text("A*: Distinction", 20, 130);

            let offsetY = 150;

            doc.text('PARTICULARS', 10, offsetY);
            doc.text('FINANCIAL SITUATION', 150, offsetY);
            doc.text('ACADEMIC PERFORMANCE', 300, offsetY);
            offsetY += 10;

            doc.text(`Name: ${shortlistedPrint?.[selectedIndex]?.student_detail?.name ?? "-"} (${shortlistedPrint?.[selectedIndex]?.adm_no})`, 20, offsetY);
            offsetY += 6;

            doc.text(`Course Code: ${shortlistedPrint?.[selectedIndex]?.student_detail?.courseList?.[0]?.courseCode ?? "-"}`, 20, offsetY);
            offsetY += 6;

            doc.text(`Study Stage: ${shortlistedPrint?.[selectedIndex]?.student_detail?.courseList?.[0]?.studyStage ?? "-"}`, 20, offsetY);
            offsetY += 6;

            doc.text(`Address:`, 20, offsetY);
            doc.text(`TERM 1: ${shortlistedPrint?.[selectedIndex]?.student_detail?.termaddr1 ?? "-"}`, 30, offsetY + 6);
            doc.text(`TERM 2: ${shortlistedPrint?.[selectedIndex]?.student_detail?.termaddr2 ?? "-"}`, 30, offsetY + 12);
            doc.text(`TERM 3: ${shortlistedPrint?.[selectedIndex]?.student_detail?.termaddr3 ?? "-"}`, 30, offsetY + 18);
            offsetY += 24;

            doc.text(`Tel:`, 20, offsetY);
            doc.text(`TEL 1: ${shortlistedPrint?.[selectedIndex]?.student_detail?.termtel ?? "-"}`, 30, offsetY + 6);
            doc.text(`TEL 2: ${shortlistedPrint?.[selectedIndex]?.student_detail?.termtel_2 ?? "-"}`, 30, offsetY + 12);
            offsetY += 18;

            doc.text(`HP: ${shortlistedPrint?.[selectedIndex]?.student_detail?.handphone ?? "-"}`, 20, offsetY);
            offsetY += 12;

            offsetY -= 70;
            doc.text(`Gross Mthly Income: $${shortlistedPrint?.[selectedIndex]?.gross_income ?? "-"}`, 150, offsetY);
            offsetY += 6;

            doc.text(`Gross Household Income: $${shortlistedPrint?.[selectedIndex]?.ccc_income ?? "-"}`, 150, offsetY);
            offsetY += 6;

            const numFamilies = Number(shortlistedPrint?.[selectedIndex]?.parent?.length ?? 0) + Number(shortlistedPrint?.[selectedIndex]?.sibling?.length ?? 0);
            doc.text(`No. of families: ${numFamilies}`, 150, offsetY);
            offsetY += 6;

            shortlistedPrint?.[selectedIndex]?.parent?.forEach((parent) => {
                const relationship = parent?.relationship === "M" ? "Mother" : "Father";
                doc.text(`${relationship} (${parent?.age ?? "-"})`, 160, offsetY);
                offsetY += 6;
            });

            shortlistedPrint?.[selectedIndex]?.sibling?.forEach((sibling) => {
                doc.text(`${sibling?.relationshipDesc} (${sibling?.age})`, 150, offsetY);
                offsetY += 6;
            });

            doc.text(`MPCI: $${shortlistedPrint?.[selectedIndex]?.mpci ?? "-"}`, 150, offsetY);
            offsetY += 6;

            doc.text(`FPCI: $${shortlistedPrint?.[selectedIndex]?.fpci ?? "-"}`, 150, offsetY);
            offsetY += 6;

            doc.text(`Special Details: ${shortlistedPrint?.[selectedIndex]?.SPECIAL_DETAILS ?? "-"}`, 150, offsetY);
            offsetY += 12;

            offsetY -= 60;
            doc.text(`Entry Qual: ${shortlistedPrint?.[selectedIndex]?.student_detail?.courseList?.[0]?.entryQual ?? "-"}`, 300, offsetY);
            offsetY += 6;

            doc.text(`GCE O Level Aggregate: ${shortlistedPrint?.[selectedIndex]?.student_detail?.OLAGG ?? "-"}`, 300, offsetY);
            offsetY += 6;

            doc.text(`CCA Grade (Points): ${shortlistedPrint?.[selectedIndex]?.student_detail?.CCAList?.length > 0
                ? shortlistedPrint?.[selectedIndex].student_detail.CCAList.map((list) => parseInt(list.ccaPoint)).reduce((a, b) => a + b, 0)
                : "-"
            }`, 300, offsetY);
            offsetY += 12;

            // Add a gap between items
            offsetY += 10;

            autoTable(doc, {
                startY: 500,
                html: `#course-table${selectedIndex}`,
                headStyles: {fillColor: [9, 230, 42], textColor: [255, 255, 255]},
                columnStyles: {
                    0: {cellWidth: 60},
                    1: {cellWidth: 40},
                    2: {cellWidth: 40},
                },
                styles: {
                    fontSize: 10,
                    cellPadding: 4,
                    valign: "middle",
                    overflow: "linebreak",
                },
                margin: {top: 20},
            });

            doc.save("shortlisted-applicants.pdf");
        } else if (optionChecked == "CSV") {
            exportToXlsx();
        }
    };

    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();
            const academyYears = response?.data?.data?.map((item) => ({
                label: item?.acad_yr,
                value: item?.acad_yr,
            }))
            const sortedAcademyYears = sortDescendingByKey(academyYears, "value");

            setDataAcademyYear(sortedAcademyYears);


        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };

    const handleGetListAward = async (codeType = "") => {
        setLoadingListAward(true);

        try {
            setAward(null);

            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setAwardType({label: "", value: ""});

            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                    codetype: item?.codetype,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );

            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const [awardTypeList, setAwardTypeList] = useState([])

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAwardType();

        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.code,
                    code: content.code,
                });
            });
            setAwardTypeList(contentArray);
        });
    }, []);

    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        if (awardType?.value) handleGetListAward(awardType?.value);
    }, [awardType]);

    return (
        <Layout currentNav={"reports"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Shortlisted Applicants</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Print Shortlisted Applicants</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                {" "}
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        className={` d-flex flex-column gap-3 collapse-animation`}
                    >
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        onChange={(value) => setAcademyYear(value)}
                                        options={dataAcademyYear}
                                        placeholder={`Academic Year`}
                                        value={academyYear}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        isDisabled={!academyYear?.value}
                                        onChange={(value) => setExercise(value)}
                                        options={dataExercise}
                                        value={exercise}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Award Type</Form.Label>
                                    <Select
                                        onChange={(value) => setAwardType(value)}
                                        options={dataAwardType}
                                        value={awardType}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Award</Form.Label>
                                    <Select
                                        isDisabled={!awardType?.value}
                                        onChange={(value) => setAward(value)}
                                        options={dataAward}
                                        value={award}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Order by</Form.Label>
                                    <Select
                                        options={orderByList}
                                        value={orderByField}
                                        onChange={(selected) => setOrderByField(selected)}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>{" "}
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {showRetrieve &&
                    shortlistedPrint?.map((item, index) => (
                        <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                            <Row>
                                <Col xs={12}>
                                    <div className="text-end">
                                        <Button
                                            type="button"
                                            className="btn btn-primary ms-auto"
                                            onClick={() => {
                                                setSelectedIndex(index);
                                                setShowModalExport(true);
                                            }}
                                        >
                                            Export
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <Row
                                    id="header-pdf"
                                    ref={(el) => (headerRefs.current[index] = el)}
                                >
                                    <Col xs={12}>
                                        <h3 className="text-center mb-6">
                                            Shortlisted Applicants Report
                                        </h3>
                                    </Col>
                                    <Col xs={12}>
                                        <h5>
                                            <u>
                                                Shortlisted Students
                                                for {item?.rec_award_code == null ? "-" : item?.rec_award_code} for {item?.acad_yr}
                                                {item.exercise ? (` Exercise ${item.exercise}`) : null}

                                            </u>
                                        </h5>
                                    </Col>
                                    <Col xs={4}>
                                        <h5>
                                            Quantum: {item?.quantum}
                                        </h5>
                                    </Col>
                                    <Col xs={4}>
                                        <h5>
                                            Eligibility Criteria: <span>{item?.criteria?.criteria}</span>
                                        </h5>
                                    </Col>
                                    <Col xs={4}>
                                        <h5>
                                            No. of awards: <span>{shortlistedPrint?.length}</span>
                                        </h5>
                                    </Col>
                                    <Col xs={12}>
                                        <h6>
                                            Recommended Award
                                            Type: {item?.rec_award_type} {item?.rec_award_type ? (`(${awardTypeList.find(option => option.value === item?.rec_award_type)?.label})`) : "-"}
                                        </h6>
                                        <h6>
                                            Recommended Award Code: {item?.rec_award_code} {item?.rec_award_code ? (`(${item?.rec_award_code_description})`) : "-"}
                                        </h6>
                                        <h6>A*: Distinction</h6>
                                    </Col>
                                    <Col xs={12}>
                                        <h6>Notes</h6>
                                        <h6>
                                            MPCI: Monthly Per Capital Income (Gross Mthly Income / No
                                            of Dependents in Household)
                                        </h6>
                                        <h6>
                                            FPCI: Family Per Capital Income (Gross Mthly Income * 12 /
                                            No of Dependents in Household)
                                        </h6>
                                        <h6>A*: Distinction</h6>
                                    </Col>
                                </Row>
                                <Table
                                    responsive
                                    ref={(el) => (tbls.current[index] = el)}
                                    id={`my-table${index}`}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>PARTICULARS</Table.HeaderCell>
                                            <Table.HeaderCell>FINANCIAL SITUATION</Table.HeaderCell>
                                            <Table.HeaderCell>ACADEMIC PERFORMANCE</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row key={1}>
                                            <Table.DataCell>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Name:</div>
                                                    <div>{item?.student_detail?.name ?? "-"} ({item?.adm_no})</div>
                                                </div>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Course Code:</div>
                                                    <div>
                                                        {item?.student_detail?.courseList?.length > 0
                                                            ? item?.student_detail?.courseList[0]?.courseCode
                                                            : "-"}
                                                    </div>
                                                </div>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Study Stage:</div>
                                                    <div>
                                                        {item?.student_detail?.courseList?.length > 0
                                                            ? item?.student_detail?.courseList[0]?.studyStage
                                                            : "-"}
                                                    </div>
                                                </div>
                                                <div className={``}>
                                                    <div>Address:</div>
                                                    <div className={`d-flex gap-3 flex-column ms-2`}>
                                                        <div>TERM 1 :{item?.student_detail?.termaddr1}</div>
                                                        <div>TERM 2 :{item?.student_detail?.termaddr2}</div>
                                                        <div>TERM 3 :{item?.student_detail?.termaddr3}</div>
                                                    </div>
                                                </div>
                                                <div className={``}>
                                                    <div>Tel:</div>
                                                    <div className={`d-flex flex-column ms-2`}>
                                                        <div>TEL 1 :{item?.student_detail?.termtel}</div>
                                                        <div>TEL 2 :{item?.student_detail?.termtel_2}</div>
                                                    </div>
                                                </div>
                                                <div className={`d-flex gap-3`}>
                                                    <div>HP:</div>
                                                    <div>{item?.student_detail?.handphone}</div>
                                                </div>
                                            </Table.DataCell>
                                            <Table.DataCell>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Gross Mthly Income:</div>
                                                    <div>${item?.gross_income}</div>
                                                </div>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Gross Household Income:</div>
                                                    <div>${item?.ccc_income}</div>
                                                </div>
                                                <div className={`d-flex gap-3`}>
                                                    <div>No. of families:</div>
                                                    <div>{Number(item?.parent?.length) + Number(item?.sibling?.length)}</div>
                                                </div>
                                                <div>
                                                    {item?.parent?.map((content, index) => (
                                                        <div key={index}>
                                                            {content?.relationship == "M" ? `Mother (${content?.age ?? "-"})` : `Father (${content?.age ?? "-"})`}
                                                        </div>
                                                    ))}
                                                    {item?.sibling?.map((content, index) => (
                                                        <div key={index}>
                                                            {content?.relationshipDesc} ({content?.age})
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={``}>
                                                    <div>MPCI: ${item?.mpci}</div>
                                                    <div>FPCI: ${item?.fpci}</div>
                                                </div>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Special Details:</div>
                                                    <div>{item?.SPECIAL_DETAILS}</div>
                                                </div>
                                            </Table.DataCell>
                                            <Table.DataCell>
                                                <div className={`d-flex gap-3`}>
                                                    <div>Entry Qual:</div>
                                                    <div>
                                                        {item?.student_detail?.courseList?.length > 0
                                                            ? item?.student_detail?.courseList[0]?.entryQual
                                                            : "-"}
                                                    </div>
                                                </div>
                                                <div className={`d-flex flex-column`}>
                                                    <div>GCE O Level Aggregate: {item?.student_detail?.OLAGG}</div>
                                                    <div>
                                                        CCA Grade (Points):{" "}
                                                        {item?.student_detail?.CCAList?.length > 0
                                                            ? item?.student_detail?.CCAList.map((list) =>
                                                                parseInt(list.ccaPoint)
                                                            ).reduce((prev, next) => prev + next)
                                                            : "-"}
                                                    </div>
                                                </div>
                                            </Table.DataCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>

                            <Row>
                                <Col xs={12} className="">
                                    <Table
                                        size={"sm"}
                                        id={`course-table${index}`}
                                        ref={(el) => (courseTableRefs.current[index] = el)}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Subject</Table.HeaderCell>
                                                <Table.HeaderCell>Year</Table.HeaderCell>
                                                <Table.HeaderCell>Grade</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {item?.student_detail?.courseList?.length > 0
                                                ? item?.student_detail?.courseList[0]?.studyPgmsList?.map(
                                                    (module, indexModule) => {
                                                        return (
                                                            <Table.Row key={indexModule}>
                                                                <Table.DataCell>
                                                                    {module?.moduleDesc}
                                                                </Table.DataCell>
                                                                <Table.DataCell>
                                                                    {module?.acadYr}
                                                                </Table.DataCell>
                                                                <Table.DataCell>
                                                                    {module?.moduleScore}
                                                                </Table.DataCell>
                                                            </Table.Row>
                                                        );
                                                    }
                                                )
                                                : "-"}
                                        </Table.Body>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    ))}
                <CustomModal
                    show={showModalExport}
                    title={`Export`}
                    handleClose={() => setShowModalExport(false)}
                    modalSize={"md"}
                    withIcon={false}
                    description={
                        <div>
                            <div>
                                <b>Export as</b>
                            </div>
                            <div>
                                <FormCheck
                                    type="radio"
                                    name="export"
                                    label="PDF"
                                    value="pdf"
                                    id="pdf"
                                    ref={refPDF}
                                />
                                <FormCheck
                                    ref={refCSV}
                                    type="radio"
                                    name="export"
                                    label="CSV"
                                    value="csv"
                                    id="csv"
                                />
                            </div>
                        </div>
                    }
                    submitButtonText={`Export`}
                    action={handleExport}
                />
            </sgds-content-body>
        </Layout>
    );
}

export default PrintShortlistedApplicants;
