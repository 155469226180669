import {Button,} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useState} from "react";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import {
    deleteOccupation,
    getOccupationList,
    postOccupation,
    putOccupation,
} from "@/apis/preparation/setup-occupation/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import SortableTable from "@/components/SortableTable";

export default function Occupation() {
    const [data, setData] = useState([
        {
            occupation: "SR",
            income_contribution: "Y",
            independent: "Y",
            remark: "Y",
            obsolete_flag: "Y",
        },
    ]);

    const [header, setHeader] = useState([
        {key: 'index', label: '#', required: false},
        {key: 'occupation', label: 'Occupation', required: true},
        {key: 'income_contribution', label: 'Monthly Income/Contribution', required: false},
        {key: 'independent', label: 'Financially Independent', required: false},
        {key: 'remark', label: 'Remark', required: false},
        {key: 'obsolete_flag', label: 'Obsolete', required: false},
        {key: 'action', label: '', required: false},
    ]);

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [selectedId, setSelectedId] = useState(null);

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [isEditing, setIsEditing] = useState(false);

    const [search, setSearch] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const itemsPerPage = 20;

    const handleAddRow = () => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setData((prevArr) => {


            const newObj = {
                occupation: null,
                income_contribution: null,
                independent: null,
                remark: null,
                obsolete_flag: null,
            };

            return [newObj, ...prevArr];
        });

        setEditArr((prevEditArr) => {
            return [false, ...prevEditArr];
        });

        setIsEditing(true);
    };

    const handleEdit = (index) => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
    };

    const handleSubmitEdit = (index, OCID) => {
        if (!data[index].occupation) {
            toast.error("Please fill out the required fields")
            return false
        }

        const OCData = {
            occupation: data[index].occupation,
            income_contribution: data[index].income_contribution,
            independent: data[index].independent,
            remark: data[index].remark,
            obsolete_flag: data[index].obsolete_flag,
        };

        //submit data
        if (OCID) {
            //update
            putOccupation(OCID, OCData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getOccupations();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postOccupation(OCData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getOccupations();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deleteOccupation(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success("Record deleted successfully");
                        getOccupations();
                        setShow(false);
                    }

                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);
            setIsEditing(false)

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const getOccupations = () => {
        getOccupationList(itemsPerPage, currentPage)
            .then((response) => {
                setData(response?.data.data);
                const newArr = Array.from({length: response?.data.data.length}).fill(
                    true
                );

                setEditArr(newArr);
                setTotalPages(response?.data?.totalPage);
                setIsNextPage(response?.data?.nextPage);
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
        getOccupations()
    }

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.id)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        Save
                    </button>

                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>
            ) : (
                <button
                    className={`btn-clear`}
                    disabled={isEditing}
                    onClick={() => handleEdit(rowIndex)}
                >
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )}

            <button
                className={`btn-clear`}
                disabled={rowIndex == 0 ? row?.id ? isEditing : false : isEditing}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.id ?? null);
                    setSelectedIndex(rowIndex);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) => (
        key === "occupation" ? editArr[rowIndex] ? (
                row[key]
            ) : (
                <input
                    type={"text"}
                    value={row[key]}
                    onChange={(e) => handleChange(rowIndex, key, e.target.value)}
                />
            )
            : (
                <input
                    type={"checkbox"}
                    checked={row[key] === "Y"}
                    disabled={editArr[rowIndex]}
                    onChange={(e) =>
                        handleChange(
                            rowIndex,
                            key,
                            e.target.checked ? "Y" : null
                        )
                    }
                />
            )


    );

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        getOccupations();
    }, [search, currentPage]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Setup Occupation</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Setup Occupation</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Button
                    variant={`outline-dark`}
                    onClick={handleAddRow}
                    className={`d-flex gap-3`}
                >
                    <i className="bi bi-plus-circle"></i>
                    Add
                </Button>
            </sgds-content-area-bottom>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable headers={header} data={data} renderActionButtons={renderActionButtons}
                                   renderCellContent={renderCellContent} setData={setData}/>

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
              {" "}
                            Page {currentPage} of {totalPages}{" "}
            </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </sgds-content-body>
            <CustomModal
                show={show}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />
        </Layout>
    );
}
