import {Button, Col, Form, Row, Table} from "@govtechsg/sgds-react";
import Select from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";

import {getListAcadYears} from "@/apis/preparation/app-timeline/api";
import {getCodeTypeSasList} from "@/apis/sims/api";
import {getDataUnmatch} from "@/apis/unmatched-cspc/api";
import {toast} from "react-toastify";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {sortDescendingByKey} from "@/components/utils/utils";

export default function UnmatchedCspc() {
    const [isOpen, setIsOpen] = useState(true);
    const [listAcadYears, setListAcadYears] = useState([]);
    const [listAwardType, setListAwardType] = useState([]);
    const [listAward, setListAward] = useState([]);
    const [dataUnmatch, setDataUnmatch] = useState([]);
    const contentRef = useRef(null);
    const [academicYear, setAcademicYear] = useState();
    const [awardType, setAwardType] = useState(null);
    const [award, setAward] = useState(null);

    const handleAcademicYearChange = (e) => {
        const newValue = e.value;
        if (newValue === null || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
        }
    };

    const getListOfAcadYears = () => {
        getListAcadYears().then((resp) => {
            if (resp.status == 200) {
                let listYears = [];
                resp?.data?.data?.map((item) => {
                    listYears.push({value: item?.acad_yr, label: item?.acad_yr});
                });

                const sortedAcademyYears = sortDescendingByKey(listYears, "value");
                setListAcadYears(sortedAcademyYears);
            }
        });
    };

    const GetAwardTypeData = () => {
        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            if (resp.status == 200) {
                let listAwardType = [];
                resp?.data?.data?.map((item) => {
                    listAwardType.push({value: item?.code, label: item?.description, codetype: item?.codetype});
                });
                setListAwardType(listAwardType);
            }
        });
    };

    const handleChangeAwardType = (e) => {
        setAwardType(e.value);

        getCodeTypeSasList(e.value).then((resp) => {
            if (resp.status == 200) {
                let listAward = [];
                resp?.data?.data?.map((item) => {
                    listAward.push({value: item?.code, label: item?.description});
                });
                setListAward(listAward);
            }
        });
    };

    const getListDataUnmatch = () => {
        getDataUnmatch(academicYear, awardType, award).then((res) => {
            setDataUnmatch(res?.data?.data);
            if (res?.data?.data?.length === 0) {
                toast.warning("Data not found");
            }
        });
    };

    const handleShowRetrieve = () => {
        getListDataUnmatch();
    };

    useEffect(() => {
        getListOfAcadYears();
        GetAwardTypeData();
    }, []);

    return (
        <Layout currentNav={"processing"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    List of Unmatched Postal Code & CS Code
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>List of Unmatched Postal Code & CS Code</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                    ? `${
                                        contentRef?.current?.scrollHeight == null
                                            ? "100%"
                                            : contentRef?.current?.scrollHeight
                                    }px`
                                    : "0px",
                            }}
                            onSubmit={() => console.log("a")}
                        >
                            <Row>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Academic Year</Form.Label>
                                        <Select
                                            value={{label: academicYear, value: academicYear}}
                                            options={listAcadYears}
                                            onChange={(e) => {
                                                handleAcademicYearChange(e);
                                            }}
                                            placeholder={`Academic Year`}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={8}>
                                    <Form.Group>
                                        <Form.Label>Award Type</Form.Label>
                                        <Select
                                            options={listAwardType}
                                            placeholder={`Types of Scholarship`}
                                            onChange={handleChangeAwardType}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Award</Form.Label>
                                        <Select
                                            options={listAward}
                                            onChange={(e) => setAward(e?.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <Button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleShowRetrieve}
                                    >
                                        Retrieve
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                        >
                            {isOpen ? "Hide " : "Expand "}{" "}
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>
                </div>
                {dataUnmatch?.length > 0 && (
                    <Table responsive>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Adm No</Table.HeaderCell>
                                <Table.HeaderCell>Postal Code</Table.HeaderCell>
                                <Table.HeaderCell>Total Count</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {dataUnmatch?.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.DataCell>{item.adm_no}</Table.DataCell>
                                    <Table.DataCell>{item.postal_code}</Table.DataCell>
                                    <Table.DataCell>{item.number_of_student}</Table.DataCell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </sgds-content-body>
        </Layout>
    );
}
