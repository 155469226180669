import {apiCall} from "@/utils/apiCall";
import {createUrlWithParams} from "@/components/utils/utils";

// {
//     "CODE_TYPE": "DG",
//     "CODE": "HA",
//     "DESCRIPTION": "hakka",
//     "CONTENT1": null,
//     "INFO1": null,
//     "INFO2": null
// }

export async function getComcareList(adminNo, schemeName, limit = 20, page = 1, AYStartDate = null, AYEndDate = null) {
    let params = {};
    if (adminNo !== null && adminNo !== "" && adminNo !== undefined) {
        params = {
            ...params,
            adm_no: adminNo,
        };
    }

    if (schemeName !== null && schemeName !== "" && schemeName !== undefined) {
        params = {
            ...params,
            schemes_name: schemeName,
        };
    }

    if (AYStartDate !== null && AYStartDate !== "" && AYStartDate !== undefined) {
        params = {
            ...params,
            ay_startdate: AYStartDate,
        };
    }

    if (AYEndDate !== null && AYEndDate !== "" && AYEndDate !== undefined) {
        params = {
            ...params,
            ay_enddate: AYEndDate,
        };
    }

    params = {
        ...params,
        limit: limit,
        page: page,
    };
    let baseUrl = `${process.env.REACT_APP_API_HOMES_URL}/homes/comcares`;
    const url = createUrlWithParams(baseUrl, params);
    return apiCall(url, {}, "GET");
}

export function postComcare(CodeData) {
    return apiCall(
        `${process.env.REACT_APP_API_HOMES_URL}/homes/comcares`,
        CodeData,
        "POST"
    );
}

export function putComcare(CodeID, CodeData) {
    return apiCall(
        `${process.env.REACT_APP_API_HOMES_URL}/homes/comcares/${CodeID}`,
        CodeData,
        "PUT"
    );
}

export function deleteComcare(selectedId) {
    return apiCall(
        `${process.env.REACT_APP_API_HOMES_URL}/homes/comcares/${selectedId}`,
        {},
        "DELETE"
    );
}

export function postAppendComcare(jsonData) {
    return apiCall(
        `${process.env.REACT_APP_API_HOMES_URL}/homes/comcares/append`,
        {
            data: jsonData,
        },
        "POST"
    );
}

export function postOverwriteComcare(jsonData) {
    return apiCall(
        `${process.env.REACT_APP_API_HOMES_URL}/homes/comcares/overwrite`,
        {
            data: jsonData,
        },
        "POST"
    );
}

